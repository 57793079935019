@import '~scss/variables';
@import '~scss/mixins';

.labeltogglebox {
	display: flex;
	position: relative;
	> * {
		vertical-align: middle;
	}
	> span {
		margin-left: 12px;
		font-family: $font-header;
		font-size: 20px;
		color: $color2;
	}

	&--popuptip {
		position: absolute;
		right: 2px;
		top: 0;
	}

	> * {
		@include transition(opacity 0.2s);
	}

	&.inactive {
		& > span, & > .togglebox {
			opacity: 0.7;
		}
	}
}

.labeltogglebox.small {
	> .togglebox {
		> div {
			width: 12px;
			height: 12px;
		}
	}

	> span {
		font-size: 18px;
	}
}
