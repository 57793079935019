@import '~scss/variables';

.login {

	&--login {
		margin-top: 22px;
		position: relative;
	}

	&--btn {
		width: 100%;
	}

	&--message {
		color: $color5;
	}

	&--divider {
		width: 100%;
		height: 2px;
		background: $color2;
		margin: 32px 0;
		position: relative;

		> * {
			position: absolute;
			display: block;
			padding: 0 10px;
			background: $color1;
			left: 50%;
			margin: 0 -50% 0 0;
			transform: translate(-50%, -50%);
		}

		&.small-margin {
			margin: 12px 0 16px 0;
		}
	}

	&--disclaimer {
		margin-top: 12px;
		font-size: 13px;
		font-style: italic;
	}

	&--forgotpass {
		margin: -8px 0 6px 0;
		font-family: $font-text;
		font-size: 13px;
	}

	&--policies {
		margin-top: 20px;
		text-align: center;

		> * {
			display: inline;
			font-family: $font-text;
			font-size: 13px;
			+ * {
				margin-left: 32px;
			}
		}
	}
}

.login-register, .login-agreeterms {
	&--disclaimer {
		margin-bottom: 12px;
		font-size: 13px;
		font-style: italic;
	}

	&--realmname {
		color: $color3;
	}

	&--agree {
		display: inline;
		margin-left: 12px;
		font-family: $font-text;
		font-size: 13px;
	}
}
