@import '~scss/variables';
@import '~scss/mixins';

.passwordinput {
	position: relative;

	> input {
		padding-right: 38px;
	}

	&--eye.iconbtn {
		@include btn-style(none, $color1-lighter, $color1-lightest, $color4-light, $color4-lighter);
		position: absolute;
		right: 3px;
		top: 3px;
	}
}

.passwordinput.darkeye {
	.passwordinput--eye.iconbtn {
		@include btn-style(none, $color4, $color4, $color1-lighter, $color1-light);
	}
}
